import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <article className="mx-auto prose prose-xl">
      <p>{`‘Hello Chukar’ is an investigative series of 25 works done over 6 weeks. Each work is 6”x6” using ink and or gouache. All of the work began with a photograph to help inform the environment. After that, the birds took on their own delightful personalities.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c4169dfaa382ac52ad55a2b6722a8b02/84cfa/IMG_2171.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcKu3NlQT0KwgT//xAAZEAEBAQEBAQAAAAAAAAAAAAABAhEAAzL/2gAIAQEAAQUC8pK6pJKBYTKqcv6wQDUO/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAADAQADAAAAAAAAAAAAAAAAASEQIjEy/9oACAEBAAY/ArnEo0qRHWeUf//EAB4QAQEBAAEEAwAAAAAAAAAAAAERAFEQITFxQWGh/9oACAEBAAE/ITH4czNRjC+9cHtP3HFBPBnfZJuQM3YPXREuP//aAAwDAQACAAMAAAAQ5C+D/8QAFREBAQAAAAAAAAAAAAAAAAAAIDH/2gAIAQMBAT8Qgf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQIBAT8QC4xn/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUXGx/9oACAEBAAE/ECUA3LLWOeSguIAXa9L2CWLC6K6IgJK1dvsoYoCWBefXP5CDBALKdzK2DCKgtbrVkvramcT/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2171",
              "title": "IMG_2171",
              "src": "/static/c4169dfaa382ac52ad55a2b6722a8b02/4b190/IMG_2171.jpg",
              "srcSet": ["/static/c4169dfaa382ac52ad55a2b6722a8b02/e07e9/IMG_2171.jpg 200w", "/static/c4169dfaa382ac52ad55a2b6722a8b02/066f9/IMG_2171.jpg 400w", "/static/c4169dfaa382ac52ad55a2b6722a8b02/4b190/IMG_2171.jpg 800w", "/static/c4169dfaa382ac52ad55a2b6722a8b02/e5166/IMG_2171.jpg 1200w", "/static/c4169dfaa382ac52ad55a2b6722a8b02/b17f8/IMG_2171.jpg 1600w", "/static/c4169dfaa382ac52ad55a2b6722a8b02/84cfa/IMG_2171.jpg 2567w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/849e85fdf203c57fe1a9f51d5f0714f1/0afc5/IMG_2172.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAECAP/aAAwDAQACEAMQAAABxNGWmM1UrmKnqG3/xAAbEAACAgMBAAAAAAAAAAAAAAABAgADERIiI//aAAgBAQABBQKVVmYladVjVimxRQwA9mqXP//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASP/xAAcEAACAQUBAAAAAAAAAAAAAAAAAREQEjFBUXH/2gAIAQEABj8CJaOkteE4o0y3ptn/xAAcEAACAgMBAQAAAAAAAAAAAAABEQAhMUFRYbH/2gAIAQEAAT8h4uxt1eZgHFJKXiEy8EAXXYEh+QMbW9zZFFczDCMkz//aAAwDAQACAAMAAAAQA+gA/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8QYf/EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/EBD/xAAfEAEAAgIBBQEAAAAAAAAAAAABABEhMXFBUWGBwaH/2gAIAQEAAT8QvNT2Bvp9lMpsjEHXGh8QJSjC1OfsS2guQFpy1zEAwUTnzr3DI1WIoQ3hae4YBtpbb78wRWAXI/Kn/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2172",
              "title": "IMG_2172",
              "src": "/static/849e85fdf203c57fe1a9f51d5f0714f1/4b190/IMG_2172.jpg",
              "srcSet": ["/static/849e85fdf203c57fe1a9f51d5f0714f1/e07e9/IMG_2172.jpg 200w", "/static/849e85fdf203c57fe1a9f51d5f0714f1/066f9/IMG_2172.jpg 400w", "/static/849e85fdf203c57fe1a9f51d5f0714f1/4b190/IMG_2172.jpg 800w", "/static/849e85fdf203c57fe1a9f51d5f0714f1/e5166/IMG_2172.jpg 1200w", "/static/849e85fdf203c57fe1a9f51d5f0714f1/b17f8/IMG_2172.jpg 1600w", "/static/849e85fdf203c57fe1a9f51d5f0714f1/0afc5/IMG_2172.jpg 2516w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fbfb3a6546c71d0dc00373b52073538b/37ad2/IMG_2173.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB8x5SMCYpcMAr/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxITITL/2gAIAQEAAQUCa0FWRcuRfKHKW8dXKnYYZ//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAQADAQADAAAAAAAAAAAAAAEAESEQAkJR/9oACAEBAAY/AgbbifOZPI9mUNzIYbz/xAAbEAACAwEBAQAAAAAAAAAAAAAAAREhQVExYf/aAAgBAQABPyFGrsQIpuIWuMd8OC4lObEzoemFuh1CA8dPL91DSz//2gAMAwEAAgADAAAAEDTQvP/EABcRAQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8Qamv/xAAXEQEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EGIr/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBUaGB/9oACAEBAAE/EAujDQthhokFtHhfzClXTXGUjxVVSXfBgQIMRFvdfYOAKRp0fcaQvAFMWOws8vuIrn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2173",
              "title": "IMG_2173",
              "src": "/static/fbfb3a6546c71d0dc00373b52073538b/4b190/IMG_2173.jpg",
              "srcSet": ["/static/fbfb3a6546c71d0dc00373b52073538b/e07e9/IMG_2173.jpg 200w", "/static/fbfb3a6546c71d0dc00373b52073538b/066f9/IMG_2173.jpg 400w", "/static/fbfb3a6546c71d0dc00373b52073538b/4b190/IMG_2173.jpg 800w", "/static/fbfb3a6546c71d0dc00373b52073538b/e5166/IMG_2173.jpg 1200w", "/static/fbfb3a6546c71d0dc00373b52073538b/b17f8/IMG_2173.jpg 1600w", "/static/fbfb3a6546c71d0dc00373b52073538b/37ad2/IMG_2173.jpg 2722w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/dd3a0ecd32e91de56546648c587b8161/9462b/IMG_2174.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHnowtyvoYcggN//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAxETBBAS/9oACAEBAAEFAqrMxblPSN4YWDB5RWaxNYM1Cf/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwFiK//EABsQAAIBBQAAAAAAAAAAAAAAAAAREAESMUFR/9oACAEBAAY/AkchlRZi02f/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMVEQQcH/2gAIAQEAAT8hdbVOLikaLBfFyL8zYmjjXVq94194OdoOPDdM/9oADAMBAAIAAwAAABAUP0D/xAAWEQEBAQAAAAAAAAAAAAAAAAAQARH/2gAIAQMBAT8Q0U//xAAWEQADAAAAAAAAAAAAAAAAAAABICH/2gAIAQIBAT8QFQ//xAAeEAEBAAMAAgMBAAAAAAAAAAABEQAhQVFxMWGB0f/aAAgBAQABPxBw0AjYdg+pbrxgKggtLy+MqamGpJJe/YwyiqAaf3+4PoicfbK5ee43I5XbTuW7HwN5/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2174",
              "title": "IMG_2174",
              "src": "/static/dd3a0ecd32e91de56546648c587b8161/4b190/IMG_2174.jpg",
              "srcSet": ["/static/dd3a0ecd32e91de56546648c587b8161/e07e9/IMG_2174.jpg 200w", "/static/dd3a0ecd32e91de56546648c587b8161/066f9/IMG_2174.jpg 400w", "/static/dd3a0ecd32e91de56546648c587b8161/4b190/IMG_2174.jpg 800w", "/static/dd3a0ecd32e91de56546648c587b8161/e5166/IMG_2174.jpg 1200w", "/static/dd3a0ecd32e91de56546648c587b8161/b17f8/IMG_2174.jpg 1600w", "/static/dd3a0ecd32e91de56546648c587b8161/9462b/IMG_2174.jpg 2692w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ff354b0f89ff7ceb6d63bb7252d1424d/a8636/IMG_2175.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHGt/OjV1DqwqRrn//EAB0QAAICAQUAAAAAAAAAAAAAAAECERIAEyIjMUL/2gAIAQEAAQUCrC+DuzRNTMr0wCsw46xn/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8BI//EABgRAAIDAAAAAAAAAAAAAAAAAAACEBEh/9oACAECAQE/AbNFj//EABwQAAICAwEBAAAAAAAAAAAAAAERAAIQIXExYf/aAAgBAQAGPwLoym+Tbr0YNR5AWX9mrWn/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhQWGRcYH/2gAIAQEAAT8hfAwvXyDDfEQQHS0yYjigg7eQSKErsGNVxSxfs//aAAwDAQACAAMAAAAQ2/8APf/EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEx/9oACAEDAQE/EEBDMj0r/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQITH/2gAIAQIBAT8QGrO1xj//xAAfEAEAAgICAgMAAAAAAAAAAAABESEAMUFxUWGhsfD/2gAIAQEAAT8Qa5gjCbVnfisbVplC9Y9MafLhH9bW3YfWRMIKWJxT+jEOS1kkXeQhhw2ry+8msZAmE73igAbgG3sz/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2175",
              "title": "IMG_2175",
              "src": "/static/ff354b0f89ff7ceb6d63bb7252d1424d/4b190/IMG_2175.jpg",
              "srcSet": ["/static/ff354b0f89ff7ceb6d63bb7252d1424d/e07e9/IMG_2175.jpg 200w", "/static/ff354b0f89ff7ceb6d63bb7252d1424d/066f9/IMG_2175.jpg 400w", "/static/ff354b0f89ff7ceb6d63bb7252d1424d/4b190/IMG_2175.jpg 800w", "/static/ff354b0f89ff7ceb6d63bb7252d1424d/e5166/IMG_2175.jpg 1200w", "/static/ff354b0f89ff7ceb6d63bb7252d1424d/b17f8/IMG_2175.jpg 1600w", "/static/ff354b0f89ff7ceb6d63bb7252d1424d/a8636/IMG_2175.jpg 2659w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c7ca8ccabbf58b25a03af2535b9e4e/d7f78/IMG_2176.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABjVXbHPaS5oiBX//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMREwQQEv/aAAgBAQABBQJKtI5SjytLtEOGtYZ3GMehP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAbEAACAwADAAAAAAAAAAAAAAAAAREhMSBBYf/aAAgBAQAGPwJObE+/EYLFZCneP//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8hFEq8iKe1D4ewCz42XMsppCWOjV8Xs1wYg5FdbbYsn//aAAwDAQACAAMAAAAQ9xh8/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxBqa//EABcRAQADAAAAAAAAAAAAAAAAAAEAEBH/2gAIAQIBAT8QJjb/xAAbEAEAAwEBAQEAAAAAAAAAAAABABExIVFhof/aAAgBAQABPxBIVVVFOZUqIRK5HhFfYjcA2AjcMHgDM37OQC6g6P17FEpH1uLlUjz5EDshasTVM//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2176",
              "title": "IMG_2176",
              "src": "/static/30c7ca8ccabbf58b25a03af2535b9e4e/4b190/IMG_2176.jpg",
              "srcSet": ["/static/30c7ca8ccabbf58b25a03af2535b9e4e/e07e9/IMG_2176.jpg 200w", "/static/30c7ca8ccabbf58b25a03af2535b9e4e/066f9/IMG_2176.jpg 400w", "/static/30c7ca8ccabbf58b25a03af2535b9e4e/4b190/IMG_2176.jpg 800w", "/static/30c7ca8ccabbf58b25a03af2535b9e4e/e5166/IMG_2176.jpg 1200w", "/static/30c7ca8ccabbf58b25a03af2535b9e4e/b17f8/IMG_2176.jpg 1600w", "/static/30c7ca8ccabbf58b25a03af2535b9e4e/d7f78/IMG_2176.jpg 2542w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4053acdabf70800d6ec6606e5b23987e/3ae97/IMG_2177.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHxay1GR0JUCIP/xAAcEAABBAMBAAAAAAAAAAAAAAABAAIRIgMQEiP/2gAIAQEAAQUCihDOYJTULGdZD6L/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABBAMBAAAAAAAAAAAAAAABAAIQISJBYaH/2gAIAQEABj8C6mkG9hVDWmi0+I4CDH//xAAdEAACAgMAAwAAAAAAAAAAAAAAAREhMUFhEIHw/9oACAEBAAE/IY22Enk7uLK4M8TQu7T7Z+6XbKCa4S20MbcX4//aAAwDAQACAAMAAAAQk8A8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAIBABAQACAQMFAAAAAAAAAAAAAREAITFBUXEQYYGh8P/aAAgBAQABPxCIVUsDg8/HGPWLfYL09/2sX7FL1iy2Gv1wY8uAdFJNeJd4lSNpboujnGwdHAcGc5RBOk16f//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2177",
              "title": "IMG_2177",
              "src": "/static/4053acdabf70800d6ec6606e5b23987e/4b190/IMG_2177.jpg",
              "srcSet": ["/static/4053acdabf70800d6ec6606e5b23987e/e07e9/IMG_2177.jpg 200w", "/static/4053acdabf70800d6ec6606e5b23987e/066f9/IMG_2177.jpg 400w", "/static/4053acdabf70800d6ec6606e5b23987e/4b190/IMG_2177.jpg 800w", "/static/4053acdabf70800d6ec6606e5b23987e/e5166/IMG_2177.jpg 1200w", "/static/4053acdabf70800d6ec6606e5b23987e/b17f8/IMG_2177.jpg 1600w", "/static/4053acdabf70800d6ec6606e5b23987e/3ae97/IMG_2177.jpg 2472w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/29c61a82c427dda38bd2e6fd6624ee10/8ef77/IMG_2178.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGqeyBZkw3WCZhX/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxIR/9oACAEBAAEFAsKIKxw0LAvIyOTm4RLDkMZazb//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAwEBAAAAAAAAAAAAAAAAIQERMRAS/9oACAEBAAY/AnBdGkemJQKu6f/EABwQAQADAQADAQAAAAAAAAAAAAEAESExQWFxgf/aAAgBAQABPyGi0uXfJ3A+kc3CpD13kT2/WOYUiQ5LXiCw+T//2gAMAwEAAgADAAAAECf3Af/EABYRAQEBAAAAAAAAAAAAAAAAABEAEP/aAAgBAwEBPxBnf//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxAj/8QAHRABAQADAAIDAAAAAAAAAAAAAREAIVFBYXGRsf/aAAgBAQABPxC7H8WR+n1jt1ZYkzcwpwxKFAnYf5eY46MgwvxMUodK7xXhXTDHNSuzHQEjVz//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2178",
              "title": "IMG_2178",
              "src": "/static/29c61a82c427dda38bd2e6fd6624ee10/4b190/IMG_2178.jpg",
              "srcSet": ["/static/29c61a82c427dda38bd2e6fd6624ee10/e07e9/IMG_2178.jpg 200w", "/static/29c61a82c427dda38bd2e6fd6624ee10/066f9/IMG_2178.jpg 400w", "/static/29c61a82c427dda38bd2e6fd6624ee10/4b190/IMG_2178.jpg 800w", "/static/29c61a82c427dda38bd2e6fd6624ee10/e5166/IMG_2178.jpg 1200w", "/static/29c61a82c427dda38bd2e6fd6624ee10/b17f8/IMG_2178.jpg 1600w", "/static/29c61a82c427dda38bd2e6fd6624ee10/8ef77/IMG_2178.jpg 2657w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f0c50f1ae3991ff8e9ddf59768f722bd/f0328/IMG_2179.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "103.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAAB3i0s1NUVsxGAp//EABwQAAICAgMAAAAAAAAAAAAAAAABAhEDEiEiQf/aAAgBAQABBQLQcShvn2jRVXWWVp//xAAXEQEAAwAAAAAAAAAAAAAAAAAQARES/9oACAEDAQE/AdQUf//EABcRAQADAAAAAAAAAAAAAAAAABABERL/2gAIAQIBAT8BzJZ//8QAGBAAAgMAAAAAAAAAAAAAAAAAABARMUH/2gAIAQEABj8CdvVB/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFRQbHR/9oACAEBAAE/IXB9im6GqOImrJwmWKc03YnWeWykj0Q//9oADAMBAAIAAwAAABCI18H/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QgdNkbbf/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EMJGOqc//8QAHxABAAIBBAMBAAAAAAAAAAAAAQARITFBUWFxgZGx/9oACAEBAAE/ECKqtyOupq4NcxaqPkBSACjugWoJWXcHP7Fs1DNVv1GRlpvVfnWJIrtXJ7lXCgcyf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2179",
              "title": "IMG_2179",
              "src": "/static/f0c50f1ae3991ff8e9ddf59768f722bd/4b190/IMG_2179.jpg",
              "srcSet": ["/static/f0c50f1ae3991ff8e9ddf59768f722bd/e07e9/IMG_2179.jpg 200w", "/static/f0c50f1ae3991ff8e9ddf59768f722bd/066f9/IMG_2179.jpg 400w", "/static/f0c50f1ae3991ff8e9ddf59768f722bd/4b190/IMG_2179.jpg 800w", "/static/f0c50f1ae3991ff8e9ddf59768f722bd/e5166/IMG_2179.jpg 1200w", "/static/f0c50f1ae3991ff8e9ddf59768f722bd/b17f8/IMG_2179.jpg 1600w", "/static/f0c50f1ae3991ff8e9ddf59768f722bd/f0328/IMG_2179.jpg 2707w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2904c74923c0fb1724dd128e90181d5e/dfb08/IMG_2181.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGYtfKuZoDTQagTf//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhASAxEx/9oACAEBAAEFAnIiHHeToVVUR//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BRop//8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8BWP/EABcQAAMBAAAAAAAAAAAAAAAAABARIFH/2gAIAQEABj8CKyv/xAAdEAADAAICAwAAAAAAAAAAAAAAAREhMUFhgZGh/9oACAEBAAE/IaSXbhDbhrkdEqvoaWg8EN2usSs59kP/2gAMAwEAAgADAAAAECMIPf/EABkRAAIDAQAAAAAAAAAAAAAAAAABEBEhMf/aAAgBAwEBPxCzbMHUf//EABgRAQEAAwAAAAAAAAAAAAAAAAEAEBEx/9oACAECAQE/ECAtTzH/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGB/9oACAEBAAE/EG8WdFQBRp9h1Vpykuens3t61hW4PksaqCo9hGnW7CBoMJ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2181",
              "title": "IMG_2181",
              "src": "/static/2904c74923c0fb1724dd128e90181d5e/4b190/IMG_2181.jpg",
              "srcSet": ["/static/2904c74923c0fb1724dd128e90181d5e/e07e9/IMG_2181.jpg 200w", "/static/2904c74923c0fb1724dd128e90181d5e/066f9/IMG_2181.jpg 400w", "/static/2904c74923c0fb1724dd128e90181d5e/4b190/IMG_2181.jpg 800w", "/static/2904c74923c0fb1724dd128e90181d5e/e5166/IMG_2181.jpg 1200w", "/static/2904c74923c0fb1724dd128e90181d5e/b17f8/IMG_2181.jpg 1600w", "/static/2904c74923c0fb1724dd128e90181d5e/dfb08/IMG_2181.jpg 2713w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/ec2be/IMG_2182.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAUB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/aAAwDAQACEAMQAAABs8jRmvoOeV6sgyIpf//EABoQAAMBAAMAAAAAAAAAAAAAAAABAhESEzH/2gAIAQEAAQUCpvdaOZXtUdrKQ0thav/EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwF2M//EABgRAQADAQAAAAAAAAAAAAAAAAEAAhEQ/9oACAECAQE/AQ0mMrz/xAAVEAEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQAGPwIQ/wD/xAAcEAACAwEAAwAAAAAAAAAAAAAAAREhQVExYaH/2gAIAQEAAT8hepUcZMptijUMereGuKgjz6Lhvg9H0Jc/F4f/2gAMAwEAAgADAAAAEBQ3P//EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhUf/aAAgBAwEBPxDURcFUx//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/ECFUPsf/xAAdEAEBAAIDAAMAAAAAAAAAAAABEQAhMUFhUYHw/9oACAEBAAE/EBooXwLkKcsjyGsW0hdOuMIEoPtPDLsgADt+6wqId3xjUmxTNYQKAxKFUdM//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2182",
              "title": "IMG_2182",
              "src": "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/4b190/IMG_2182.jpg",
              "srcSet": ["/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/e07e9/IMG_2182.jpg 200w", "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/066f9/IMG_2182.jpg 400w", "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/4b190/IMG_2182.jpg 800w", "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/e5166/IMG_2182.jpg 1200w", "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/b17f8/IMG_2182.jpg 1600w", "/static/5586be3e7c8de380fd2f1b6a3bfb6f8e/ec2be/IMG_2182.jpg 2597w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fa8fddcad6b06c8639840caf221ae528/1c87d/IMG_2190.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAbIRNUDSRU3A/wD/xAAdEAACAgIDAQAAAAAAAAAAAAABAgADESESEyIx/9oACAEBAAEFAtTyUHzHFbLOwpWcMdIBBP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAICAgMAAAAAAAAAAAAAAAARASEQMSJRYf/aAAgBAQAGPwKPeizY5tHGEXKEax//xAAbEAADAQEAAwAAAAAAAAAAAAAAAREhMUFRYf/aAAgBAQABPyGtCafh9FoivkJehGgmo+CdFoI529McmXgpcYJUf//aAAwDAQACAAMAAAAQ6O/C/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ECv/xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EGYOf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFxgZH/2gAIAQEAAT8QFaSBQ+CvJVUuAb9TYS79y/uPBS9kFmACp+FQKFX0JEY0+S+zv90KbBuez//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_2190",
              "title": "IMG_2190",
              "src": "/static/fa8fddcad6b06c8639840caf221ae528/4b190/IMG_2190.jpg",
              "srcSet": ["/static/fa8fddcad6b06c8639840caf221ae528/e07e9/IMG_2190.jpg 200w", "/static/fa8fddcad6b06c8639840caf221ae528/066f9/IMG_2190.jpg 400w", "/static/fa8fddcad6b06c8639840caf221ae528/4b190/IMG_2190.jpg 800w", "/static/fa8fddcad6b06c8639840caf221ae528/e5166/IMG_2190.jpg 1200w", "/static/fa8fddcad6b06c8639840caf221ae528/b17f8/IMG_2190.jpg 1600w", "/static/fa8fddcad6b06c8639840caf221ae528/1c87d/IMG_2190.jpg 2270w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e3a60abec7ec19eb0e0d115dae2c190c/9cd6c/IMG_3540.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGr18zbNSzYoSgB/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAwQSEBQh/9oACAEBAAEFAqLYmSp4rfRlt87bLNBAgMKAT//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAARARAhUf/aAAgBAQAGPwJG5HaZIopV/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBkcH/2gAIAQEAAT8hRc7eXNAbKtLmgX8hCLY+xGNYG5k7EHOiIVmf/9oADAMBAAIAAwAAABBAyAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhUTFBYYHw/9oACAEBAAE/EF6QhWiNoZ1zeK4iVYIBg1eoxbUhK99EvNLBtt3AmnBTr9j5G2dK7j1ow4cuJTLU4tn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_3540",
              "title": "IMG_3540",
              "src": "/static/e3a60abec7ec19eb0e0d115dae2c190c/4b190/IMG_3540.jpg",
              "srcSet": ["/static/e3a60abec7ec19eb0e0d115dae2c190c/e07e9/IMG_3540.jpg 200w", "/static/e3a60abec7ec19eb0e0d115dae2c190c/066f9/IMG_3540.jpg 400w", "/static/e3a60abec7ec19eb0e0d115dae2c190c/4b190/IMG_3540.jpg 800w", "/static/e3a60abec7ec19eb0e0d115dae2c190c/e5166/IMG_3540.jpg 1200w", "/static/e3a60abec7ec19eb0e0d115dae2c190c/b17f8/IMG_3540.jpg 1600w", "/static/e3a60abec7ec19eb0e0d115dae2c190c/9cd6c/IMG_3540.jpg 2968w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/15fa84886130e9633bcb42aeb1a8826d/51418/IMG_4095.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "108%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAIEAQMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7U1QtojGsJg//8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhAS/9oACAEBAAEFArON6iDgfU//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAABEAAREiFB/9oACAEBAAY/AgcqmmeM2v/EABsQAQEBAAIDAAAAAAAAAAAAAAERABBBMWFx/9oACAEBAAE/ITQQOusAVU5XV9HrfdcEkw8Brv/aAAwDAQACAAMAAAAQE88A/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAQEBAAMBAAAAAAAAAAAAAREAMSFRYUH/2gAIAQEAAT8Qsig5K917y6ePuHXLfDgYeGdr7hYeA/syozuoqQOY8Wv25d3/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG_4095",
              "title": "IMG_4095",
              "src": "/static/15fa84886130e9633bcb42aeb1a8826d/4b190/IMG_4095.jpg",
              "srcSet": ["/static/15fa84886130e9633bcb42aeb1a8826d/e07e9/IMG_4095.jpg 200w", "/static/15fa84886130e9633bcb42aeb1a8826d/066f9/IMG_4095.jpg 400w", "/static/15fa84886130e9633bcb42aeb1a8826d/4b190/IMG_4095.jpg 800w", "/static/15fa84886130e9633bcb42aeb1a8826d/e5166/IMG_4095.jpg 1200w", "/static/15fa84886130e9633bcb42aeb1a8826d/b17f8/IMG_4095.jpg 1600w", "/static/15fa84886130e9633bcb42aeb1a8826d/51418/IMG_4095.jpg 2944w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2666a8df58bc9bf656f3c19237e63f19/7d013/IMG-2298.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB8blEWDMCgf/EABoQAAICAwAAAAAAAAAAAAAAAAABAhEQITH/2gAIAQEAAQUCL1ZwcrEhix//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAbEAADAQEAAwAAAAAAAAAAAAAAAREhMRBRYf/aAAgBAQABPyFNYR0L6MS9hbiHTWNkFXrx/9oADAMBAAIAAwAAABATCDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/EMQRvlZFS0tLAjV1LthC48YWwNeweQfjkYY5HXjIFls//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2298",
              "title": "IMG-2298",
              "src": "/static/2666a8df58bc9bf656f3c19237e63f19/4b190/IMG-2298.jpg",
              "srcSet": ["/static/2666a8df58bc9bf656f3c19237e63f19/e07e9/IMG-2298.jpg 200w", "/static/2666a8df58bc9bf656f3c19237e63f19/066f9/IMG-2298.jpg 400w", "/static/2666a8df58bc9bf656f3c19237e63f19/4b190/IMG-2298.jpg 800w", "/static/2666a8df58bc9bf656f3c19237e63f19/e5166/IMG-2298.jpg 1200w", "/static/2666a8df58bc9bf656f3c19237e63f19/7d013/IMG-2298.jpg 1343w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5bbd8d86400fc0423d43fa092422fca1/b0376/IMG-2305.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAbLMMydzCHnTKAN//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxESIkH/2gAIAQEAAQUCWekCKx23Zgb8HciV9Gzlv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAABECExEf/aAAgBAQAGPwI4kpy09Lmj/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRQZH/2gAIAQEAAT8hBdbg8B1inUGrPuooEIFSbhwZKzE0r55G0cXP/9oADAMBAAIAAwAAABCsIEP/xAAXEQEAAwAAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/EAJl/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QVtZgL//EAB4QAQACAgIDAQAAAAAAAAAAAAEAESExQVGRwdHw/9oACAEBAAE/EE8ggDSwvEt9fZYE6lnGHJgBca7i2BYNKL16irUlAsFCzzLerWKl4iNwNuH7UEthKULVn//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2305",
              "title": "IMG-2305",
              "src": "/static/5bbd8d86400fc0423d43fa092422fca1/4b190/IMG-2305.jpg",
              "srcSet": ["/static/5bbd8d86400fc0423d43fa092422fca1/e07e9/IMG-2305.jpg 200w", "/static/5bbd8d86400fc0423d43fa092422fca1/066f9/IMG-2305.jpg 400w", "/static/5bbd8d86400fc0423d43fa092422fca1/4b190/IMG-2305.jpg 800w", "/static/5bbd8d86400fc0423d43fa092422fca1/e5166/IMG-2305.jpg 1200w", "/static/5bbd8d86400fc0423d43fa092422fca1/b17f8/IMG-2305.jpg 1600w", "/static/5bbd8d86400fc0423d43fa092422fca1/b0376/IMG-2305.jpg 1784w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e00f7510b56dbac95cebd367200be04c/93a43/IMG-2308.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABXJpZjtLj6MroH//EABsQAAMAAgMAAAAAAAAAAAAAAAABEQIDEyEi/9oACAEBAAEFAlnicqHu7iFGVCL6P//EABgRAAMBAQAAAAAAAAAAAAAAAAABEhAx/9oACAEDAQE/AZZIuZ//xAAYEQACAwAAAAAAAAAAAAAAAAAAARASE//aAAgBAgEBPwGyNEOP/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBEBEg/9oACAEBAAY/AnSFhgo4/8QAGxABAQEBAQADAAAAAAAAAAAAAREAITFRYXH/2gAIAQEAAT8hgsYp4p8hhIEmGlt+49s59YwOlfSbroM8Zv/aAAwDAQACAAMAAAAQ48//AP/EABcRAQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QF2of/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQIWH/2gAIAQIBAT8QPAq4/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARIUExUcHh/9oACAEBAAE/EDguJivyXZQ5sFj4Q7hNodUi2QO84gRKC8z8iWlgRfTL0Iqi+xXDxdz/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2308",
              "title": "IMG-2308",
              "src": "/static/e00f7510b56dbac95cebd367200be04c/4b190/IMG-2308.jpg",
              "srcSet": ["/static/e00f7510b56dbac95cebd367200be04c/e07e9/IMG-2308.jpg 200w", "/static/e00f7510b56dbac95cebd367200be04c/066f9/IMG-2308.jpg 400w", "/static/e00f7510b56dbac95cebd367200be04c/4b190/IMG-2308.jpg 800w", "/static/e00f7510b56dbac95cebd367200be04c/e5166/IMG-2308.jpg 1200w", "/static/e00f7510b56dbac95cebd367200be04c/b17f8/IMG-2308.jpg 1600w", "/static/e00f7510b56dbac95cebd367200be04c/93a43/IMG-2308.jpg 1746w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/28a975ea2483cd3388730873af8903c1/8ac54/IMG-2310.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "103%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcS7X0I7Qy1YEkV//8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAESEDIjH/2gAIAQEAAQUCnGhlCINTCRZUXK7t7//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAMBAQAAAAAAAAAAAAAAAAABERBB/9oACAEBAAY/AitbchKdP//EABwQAAICAwEBAAAAAAAAAAAAAAABESExQWFxsf/aAAgBAQABPyEmYHMnkyRG1WjlL6JkE2a2JsyyKtJ+n//aAAwDAQACAAMAAAAQQPD/AP/EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEDAQE/ECP/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPxBj/8QAHhABAAIDAAIDAAAAAAAAAAAAAQARITFBUXHB4fD/2gAIAQEAAT8QHVecRRS7ZCPuU4qnrUHYirdhSSPe38YyHrEIcvPRGhte7c1BTdLdD4n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2310",
              "title": "IMG-2310",
              "src": "/static/28a975ea2483cd3388730873af8903c1/4b190/IMG-2310.jpg",
              "srcSet": ["/static/28a975ea2483cd3388730873af8903c1/e07e9/IMG-2310.jpg 200w", "/static/28a975ea2483cd3388730873af8903c1/066f9/IMG-2310.jpg 400w", "/static/28a975ea2483cd3388730873af8903c1/4b190/IMG-2310.jpg 800w", "/static/28a975ea2483cd3388730873af8903c1/e5166/IMG-2310.jpg 1200w", "/static/28a975ea2483cd3388730873af8903c1/b17f8/IMG-2310.jpg 1600w", "/static/28a975ea2483cd3388730873af8903c1/8ac54/IMG-2310.jpg 1735w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f19dadcf0a316de32ab3be99e208183f/8ccd0/IMG-2315.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "96.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABw6HYIiNoUBP/xAAaEAADAQADAAAAAAAAAAAAAAAAAQIRAyEj/9oACAEBAAEFAsRMIfGhIyj0JfSKb3//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAACAwAAAAAAAAAAAAAAAAAAMRAgUf/aAAgBAQAGPwJTtWf/xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhMUFRgeH/2gAIAQEAAT8hS07WNkFk8G3slL8DY3mLoaXUGRkZnOj/2gAMAwEAAgADAAAAEAsHAf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/EHYdnBc2Lb0FS+2LOBESORjMPxb1bMUpsj02qb159h2AH2f/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2311",
              "title": "IMG-2311",
              "src": "/static/f19dadcf0a316de32ab3be99e208183f/4b190/IMG-2315.jpg",
              "srcSet": ["/static/f19dadcf0a316de32ab3be99e208183f/e07e9/IMG-2315.jpg 200w", "/static/f19dadcf0a316de32ab3be99e208183f/066f9/IMG-2315.jpg 400w", "/static/f19dadcf0a316de32ab3be99e208183f/4b190/IMG-2315.jpg 800w", "/static/f19dadcf0a316de32ab3be99e208183f/e5166/IMG-2315.jpg 1200w", "/static/f19dadcf0a316de32ab3be99e208183f/b17f8/IMG-2315.jpg 1600w", "/static/f19dadcf0a316de32ab3be99e208183f/8ccd0/IMG-2315.jpg 1792w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b4db45a2b384586dd329d329d4a288c9/20de7/IMG-2317.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEBQL/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQMC/9oADAMBAAIQAxAAAAGV+dsJ3RGOL7HXeFH/xAAbEAEAAgIDAAAAAAAAAAAAAAACAAEDERASIf/aAAgBAQABBQIscbmCq32Tv2blNGY8iR//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAREv/aAAgBAwEBPwHKLhn/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BI//EABsQAAEEAwAAAAAAAAAAAAAAAAABAhAhEiAy/9oACAEBAAY/AupydpSln//EABoQAAIDAQEAAAAAAAAAAAAAAAABEUFRMSH/2gAIAQEAAT8h70immPz2tZAvpLiGpVYNYJad7RKISf/aAAwDAQACAAMAAAAQRA99/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQITH/2gAIAQMBAT8QC2zh03//xAAXEQEAAwAAAAAAAAAAAAAAAAABABAR/9oACAECAQE/EFZi/wD/xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUFRYXGR4f/aAAgBAQABPxAlBHRiT3BjVpxYIMCcnUMhNmNvccG2zoPP7AOGzDVq6ufAE1LHqyWFXP/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2317",
              "title": "IMG-2317",
              "src": "/static/b4db45a2b384586dd329d329d4a288c9/4b190/IMG-2317.jpg",
              "srcSet": ["/static/b4db45a2b384586dd329d329d4a288c9/e07e9/IMG-2317.jpg 200w", "/static/b4db45a2b384586dd329d329d4a288c9/066f9/IMG-2317.jpg 400w", "/static/b4db45a2b384586dd329d329d4a288c9/4b190/IMG-2317.jpg 800w", "/static/b4db45a2b384586dd329d329d4a288c9/e5166/IMG-2317.jpg 1200w", "/static/b4db45a2b384586dd329d329d4a288c9/b17f8/IMG-2317.jpg 1600w", "/static/b4db45a2b384586dd329d329d4a288c9/20de7/IMG-2317.jpg 1748w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/62955/IMG-2319.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAMCAQQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB8WsewSZE+QyD/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAhAhQf/aAAgBAQABBQLyYyUTF0um6z//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAABBAMBAAAAAAAAAAAAAAABAAIRIRAgMUH/2gAIAQEABj8CTTN+hVhrTUFcGn//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhEGExQVFx/9oACAEBAAE/IQBfuFMaAkVGNQ3tS2wYPhnMpTrWCMOP/9oADAMBAAIAAwAAABDgwDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEAAwADAAMAAAAAAAAAAAABABEhMUFhUXGB/9oACAEBAAE/ENVqcA4IKxH2B57LAfBqYBpDsAEhWVLb5V/sbvaJD0+Ja1bxHOTxnVZKn//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2319",
              "title": "IMG-2319",
              "src": "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/4b190/IMG-2319.jpg",
              "srcSet": ["/static/b6d2c9e85c70a96dabf65e5bfd7b9243/e07e9/IMG-2319.jpg 200w", "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/066f9/IMG-2319.jpg 400w", "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/4b190/IMG-2319.jpg 800w", "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/e5166/IMG-2319.jpg 1200w", "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/b17f8/IMG-2319.jpg 1600w", "/static/b6d2c9e85c70a96dabf65e5bfd7b9243/62955/IMG-2319.jpg 1802w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/87536b463039d6cb1b2b92ad7e9a6685/69019/IMG-2321.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfPxpRJrjhqFwf/EABgQAAMBAQAAAAAAAAAAAAAAAAABAhEQ/9oACAEBAAEFAluDYmRWFa2S+f/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAQACAwAAAAAAAAAAAAAAAAEAECAxYf/aAAgBAQAGPwKwid3j/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERITFBsf/aAAgBAQABPyGu+enG4LNJ1hY2CpQWCSE2h+EH/9oADAMBAAIAAwAAABDI14H/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAVEQEBAAAAAAAAAAAAAAAAAAAQAf/aAAgBAgEBPxAp/8QAHBABAQADAQEBAQAAAAAAAAAAAREAITFBYXGR/9oACAEBAAE/EItATQQ/jNUzJ9l5hRDzzDJdOg2fc9JgLpqedwCVJ+ZMjN5TvSXGo1545//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2321",
              "title": "IMG-2321",
              "src": "/static/87536b463039d6cb1b2b92ad7e9a6685/4b190/IMG-2321.jpg",
              "srcSet": ["/static/87536b463039d6cb1b2b92ad7e9a6685/e07e9/IMG-2321.jpg 200w", "/static/87536b463039d6cb1b2b92ad7e9a6685/066f9/IMG-2321.jpg 400w", "/static/87536b463039d6cb1b2b92ad7e9a6685/4b190/IMG-2321.jpg 800w", "/static/87536b463039d6cb1b2b92ad7e9a6685/e5166/IMG-2321.jpg 1200w", "/static/87536b463039d6cb1b2b92ad7e9a6685/b17f8/IMG-2321.jpg 1600w", "/static/87536b463039d6cb1b2b92ad7e9a6685/69019/IMG-2321.jpg 1844w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ac439cf20665f65d3a485b3ae38ccb51/5f433/IMG-2322.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "103%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGPqbaSdoJRYB//xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAxITIP/aAAgBAQABBQIJpMwFBSYhou06eP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABcQAQADAAAAAAAAAAAAAAAAAAEAECD/2gAIAQEABj8CGDRr/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFRYf/aAAgBAQABPyHNGq+GpK+I0yKpS0TpNytl+lKyn//aAAwDAQACAAMAAAAQrP8A/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8QI//EABcRAAMBAAAAAAAAAAAAAAAAAAAQIUH/2gAIAQIBAT8Q0r//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAQABPxC5E0nWVNUgMtOItwKugzQUAMjwUjOL13VSr3rrOnMyWtcM3//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2322",
              "title": "IMG-2322",
              "src": "/static/ac439cf20665f65d3a485b3ae38ccb51/4b190/IMG-2322.jpg",
              "srcSet": ["/static/ac439cf20665f65d3a485b3ae38ccb51/e07e9/IMG-2322.jpg 200w", "/static/ac439cf20665f65d3a485b3ae38ccb51/066f9/IMG-2322.jpg 400w", "/static/ac439cf20665f65d3a485b3ae38ccb51/4b190/IMG-2322.jpg 800w", "/static/ac439cf20665f65d3a485b3ae38ccb51/e5166/IMG-2322.jpg 1200w", "/static/ac439cf20665f65d3a485b3ae38ccb51/b17f8/IMG-2322.jpg 1600w", "/static/ac439cf20665f65d3a485b3ae38ccb51/5f433/IMG-2322.jpg 1738w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1678b7201f1149ec3b0e562b2cd42905/0862b/IMG-2324.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHNbniHYkFz3Kphj//EABwQAAIBBQEAAAAAAAAAAAAAAAECEQATISIxMv/aAAgBAQABBQKIUHXtWzE5Xh1Z/BJU/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPwEj/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAECAQE/AdIf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAEQEXECMUH/2gAIAQEABj8C1T3Z6tikJ23pS5M//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFhQZH/2gAIAQEAAT8hMyCfcdW5UwHBFbUHgqKoyMQSwlHUxYNz/9oADAMBAAIAAwAAABB/8EP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAEDAQE/EHJAXZv/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxCm8G3Tgh//xAAgEAEAAwABAwUAAAAAAAAAAAABABEhUTFBcWGBkbHB/9oACAEBAAE/EECyYg99v8m2rdEXxFSl0qVaF6q/IfUYFdUYOJgL34h0/CdvnzBjghoF+kpiIwhl+0//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2324",
              "title": "IMG-2324",
              "src": "/static/1678b7201f1149ec3b0e562b2cd42905/4b190/IMG-2324.jpg",
              "srcSet": ["/static/1678b7201f1149ec3b0e562b2cd42905/e07e9/IMG-2324.jpg 200w", "/static/1678b7201f1149ec3b0e562b2cd42905/066f9/IMG-2324.jpg 400w", "/static/1678b7201f1149ec3b0e562b2cd42905/4b190/IMG-2324.jpg 800w", "/static/1678b7201f1149ec3b0e562b2cd42905/e5166/IMG-2324.jpg 1200w", "/static/1678b7201f1149ec3b0e562b2cd42905/b17f8/IMG-2324.jpg 1600w", "/static/1678b7201f1149ec3b0e562b2cd42905/0862b/IMG-2324.jpg 1828w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0e9929bfabed2faa17423d53a9f7ea7f/6ca16/IMG-2328.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIEBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAc7V5+xHRsFFGZqRB//EABwQAAICAgMAAAAAAAAAAAAAAAACAQMREhAUIf/aAAgBAQABBQKmwS9Z4VtZWzzstBg1MH//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BI//EABgQAAMBAQAAAAAAAAAAAAAAAAAQESFR/9oACAEBAAY/AocekUX/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMUGB/9oACAEBAAE/IWWvBhHU0mymYgi+GOXadIEjYeR//9oADAMBAAIAAwAAABDY/wBB/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ECv/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPxBIf//EAB0QAAICAgMBAAAAAAAAAAAAAAERACExQWFxgdH/2gAIAQEAAT8QvBkJCKNrxZgcwFBROuobTdjCexzAAkCSln37E6lgcoHK9QkYgA66ggjNT//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2328",
              "title": "IMG-2328",
              "src": "/static/0e9929bfabed2faa17423d53a9f7ea7f/4b190/IMG-2328.jpg",
              "srcSet": ["/static/0e9929bfabed2faa17423d53a9f7ea7f/e07e9/IMG-2328.jpg 200w", "/static/0e9929bfabed2faa17423d53a9f7ea7f/066f9/IMG-2328.jpg 400w", "/static/0e9929bfabed2faa17423d53a9f7ea7f/4b190/IMG-2328.jpg 800w", "/static/0e9929bfabed2faa17423d53a9f7ea7f/e5166/IMG-2328.jpg 1200w", "/static/0e9929bfabed2faa17423d53a9f7ea7f/b17f8/IMG-2328.jpg 1600w", "/static/0e9929bfabed2faa17423d53a9f7ea7f/6ca16/IMG-2328.jpg 1855w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c7c24e1abd88a18878f63dbf71c67366/487e4/IMG-2331.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcqNpIghpKyg/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACERIx/9oACAEBAAEFAuzlrXWHlXWxiMI+/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAACAwEAAAAAAAAAAAAAAAAQEQABMSH/2gAIAQEABj8C7EbezaDH/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFRYYH/2gAIAQEAAT8hvBZ+QVTTWnUoNWR9N7hp0zPWZ6HlxhJwKUs5p//aAAwDAQACAAMAAAAQTPB8/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQMBAT8QIf/EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ECn/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhQTFRYYH/2gAIAQEAAT8QclQ4IF7gID6FfYVEVCzk351KufRaJaN+wUjuZrGYCsEtpGm7O5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2331",
              "title": "IMG-2331",
              "src": "/static/c7c24e1abd88a18878f63dbf71c67366/4b190/IMG-2331.jpg",
              "srcSet": ["/static/c7c24e1abd88a18878f63dbf71c67366/e07e9/IMG-2331.jpg 200w", "/static/c7c24e1abd88a18878f63dbf71c67366/066f9/IMG-2331.jpg 400w", "/static/c7c24e1abd88a18878f63dbf71c67366/4b190/IMG-2331.jpg 800w", "/static/c7c24e1abd88a18878f63dbf71c67366/e5166/IMG-2331.jpg 1200w", "/static/c7c24e1abd88a18878f63dbf71c67366/b17f8/IMG-2331.jpg 1600w", "/static/c7c24e1abd88a18878f63dbf71c67366/487e4/IMG-2331.jpg 1759w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cad3341459ef8afabb8e2026665d8f2a/d2c19/IMG-2333.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEAv/EABcBAQEBAQAAAAAAAAAAAAAAAAIBAAP/2gAMAwEAAhADEAAAActc9uVomOhQU6Af/8QAFxABAQEBAAAAAAAAAAAAAAAAEQAQIv/aAAgBAQABBQLGepiM/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAECAQE/AWP/xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAaEAEBAAIDAAAAAAAAAAAAAAABABARITFB/9oACAEBAAE/IVSHXbEU6nlviMQMf//aAAwDAQACAAMAAAAQ4zBD/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBECEx/9oACAEDAQE/ENMZHa//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxB8RQ4//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8QFKvWoI4MCu3KyWePuBeebMOQC3ba7sJu59hP/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2333",
              "title": "IMG-2333",
              "src": "/static/cad3341459ef8afabb8e2026665d8f2a/4b190/IMG-2333.jpg",
              "srcSet": ["/static/cad3341459ef8afabb8e2026665d8f2a/e07e9/IMG-2333.jpg 200w", "/static/cad3341459ef8afabb8e2026665d8f2a/066f9/IMG-2333.jpg 400w", "/static/cad3341459ef8afabb8e2026665d8f2a/4b190/IMG-2333.jpg 800w", "/static/cad3341459ef8afabb8e2026665d8f2a/e5166/IMG-2333.jpg 1200w", "/static/cad3341459ef8afabb8e2026665d8f2a/b17f8/IMG-2333.jpg 1600w", "/static/cad3341459ef8afabb8e2026665d8f2a/d2c19/IMG-2333.jpg 1811w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/02c80ee551e5fa2c97faf31aa3a8b551/5cbb4/IMG-2336.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "111.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfIuz6EiqRyJUxH/xAAaEAADAAMBAAAAAAAAAAAAAAAAARECEiEQ/9oACAEBAAEFAsFXrBaMxKpwi84f/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGBAAAgMAAAAAAAAAAAAAAAAAABEBICH/2gAIAQEABj8CEbKv/8QAHhAAAwABBAMAAAAAAAAAAAAAAAERURAhMWFxgfD/2gAIAQEAAT8hRBktTUyIjkeNMOzPu5gpD2P/2gAMAwEAAgADAAAAECAnAv/EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEDAQE/ECP/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPxBj/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUZGh8P/aAAgBAQABPxDUgFlSzBMRXb9rLlQ1D6ynhQOdy4x6J8d71hXWKFknnHcuCAH0z//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2336",
              "title": "IMG-2336",
              "src": "/static/02c80ee551e5fa2c97faf31aa3a8b551/4b190/IMG-2336.jpg",
              "srcSet": ["/static/02c80ee551e5fa2c97faf31aa3a8b551/e07e9/IMG-2336.jpg 200w", "/static/02c80ee551e5fa2c97faf31aa3a8b551/066f9/IMG-2336.jpg 400w", "/static/02c80ee551e5fa2c97faf31aa3a8b551/4b190/IMG-2336.jpg 800w", "/static/02c80ee551e5fa2c97faf31aa3a8b551/e5166/IMG-2336.jpg 1200w", "/static/02c80ee551e5fa2c97faf31aa3a8b551/b17f8/IMG-2336.jpg 1600w", "/static/02c80ee551e5fa2c97faf31aa3a8b551/5cbb4/IMG-2336.jpg 1651w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/785c2619f12bbcaa6058b8e957acd8d1/1166c/IMG-2338.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAXAQADAQAAAAAAAAAAAAAAAAABAgMA/9oADAMBAAIQAxAAAAGunPaZ2Qm/JBVMwN//xAAZEAEBAAMBAAAAAAAAAAAAAAABAAIQEUL/2gAIAQEAAQUCDfYyi4Xlzb//xAAVEQEBAAAAAAAAAAAAAAAAAAASIP/aAAgBAwEBPwFR/8QAFREBAQAAAAAAAAAAAAAAAAAAEiD/2gAIAQIBAT8BMf/EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABsQAQEBAAIDAAAAAAAAAAAAAAEAESExQWGB/9oACAEBAAE/IcWwZQs736iXB+wchjMvBiOcX//aAAwDAQACAAMAAAAQNAcD/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAREP/aAAgBAwEBPxAEOmyc/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAgEBPxBeSM15/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFRQXGBocH/2gAIAQEAAT8QMqTtJefHzEaD6m0uVjIBQFjbeP2IvzrqkYLKrVvMM7AZ16hcYDzP/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2338",
              "title": "IMG-2338",
              "src": "/static/785c2619f12bbcaa6058b8e957acd8d1/4b190/IMG-2338.jpg",
              "srcSet": ["/static/785c2619f12bbcaa6058b8e957acd8d1/e07e9/IMG-2338.jpg 200w", "/static/785c2619f12bbcaa6058b8e957acd8d1/066f9/IMG-2338.jpg 400w", "/static/785c2619f12bbcaa6058b8e957acd8d1/4b190/IMG-2338.jpg 800w", "/static/785c2619f12bbcaa6058b8e957acd8d1/e5166/IMG-2338.jpg 1200w", "/static/785c2619f12bbcaa6058b8e957acd8d1/b17f8/IMG-2338.jpg 1600w", "/static/785c2619f12bbcaa6058b8e957acd8d1/1166c/IMG-2338.jpg 1831w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ba7c4502823925f38746633d4f88bc56/6382b/IMG-2340.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "96%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHj5xUiBAA//8QAGRABAAIDAAAAAAAAAAAAAAAAAQIQEiAh/9oACAEBAAEFAomS8aXX/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFRABAQAAAAAAAAAAAAAAAAAAITD/2gAIAQEABj8CSP8A/8QAGRABAQADAQAAAAAAAAAAAAAAAREAEFEg/9oACAEBAAE/ITEUdwCRp3Vyj5//2gAMAwEAAgADAAAAEBsng//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABsQAQACAgMAAAAAAAAAAAAAAAEAIRFBEDGB/9oACAEBAAE/EDArStQwY2kYz5FuCETsmeSotzXP/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2340",
              "title": "IMG-2340",
              "src": "/static/ba7c4502823925f38746633d4f88bc56/4b190/IMG-2340.jpg",
              "srcSet": ["/static/ba7c4502823925f38746633d4f88bc56/e07e9/IMG-2340.jpg 200w", "/static/ba7c4502823925f38746633d4f88bc56/066f9/IMG-2340.jpg 400w", "/static/ba7c4502823925f38746633d4f88bc56/4b190/IMG-2340.jpg 800w", "/static/ba7c4502823925f38746633d4f88bc56/e5166/IMG-2340.jpg 1200w", "/static/ba7c4502823925f38746633d4f88bc56/b17f8/IMG-2340.jpg 1600w", "/static/ba7c4502823925f38746633d4f88bc56/6382b/IMG-2340.jpg 1867w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/063444745071ecbe33c3cda9742c8f3b/d276c/IMG-2342.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAYAQADAQEAAAAAAAAAAAAAAAACAwQAAf/aAAwDAQACEAMQAAABXYNCtqUAZzbSuWA6H//EABwQAAMAAQUAAAAAAAAAAAAAAAABAhIQERMhIv/aAAgBAQABBQKJ6S205CL85CZTMmf/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPwEY/8QAGBEAAgMAAAAAAAAAAAAAAAAAAREAAhD/2gAIAQIBAT8BNUI9/8QAGRAAAQUAAAAAAAAAAAAAAAAAEQABECAh/9oACAEBAAY/ApC1hX//xAAdEAACAgIDAQAAAAAAAAAAAAAAARExIUEQUWGh/9oACAEBAAE/IWWEcMlg1iBFCX0hobWuhsrwUFn/2gAMAwEAAgADAAAAEDfAPf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAERD/2gAIAQMBAT8QNctOLf/EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFx/9oACAECAQE/EHWMxP8A/8QAHBABAQEAAwADAAAAAAAAAAAAAREAITFxQVGR/9oACAEBAAE/EKNinXPWMGi38db1M2gLSfXuOOoSE6S4f4z2ZsTU+MwwCOJoXPf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2342",
              "title": "IMG-2342",
              "src": "/static/063444745071ecbe33c3cda9742c8f3b/4b190/IMG-2342.jpg",
              "srcSet": ["/static/063444745071ecbe33c3cda9742c8f3b/e07e9/IMG-2342.jpg 200w", "/static/063444745071ecbe33c3cda9742c8f3b/066f9/IMG-2342.jpg 400w", "/static/063444745071ecbe33c3cda9742c8f3b/4b190/IMG-2342.jpg 800w", "/static/063444745071ecbe33c3cda9742c8f3b/e5166/IMG-2342.jpg 1200w", "/static/063444745071ecbe33c3cda9742c8f3b/b17f8/IMG-2342.jpg 1600w", "/static/063444745071ecbe33c3cda9742c8f3b/d276c/IMG-2342.jpg 1807w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/766af3f0e876dd5efbe879177a0de813/1deb5/IMG-2344.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "107.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9anccaUUaYlTEf/EABoQAQADAAMAAAAAAAAAAAAAAAEAEBECEiH/2gAIAQEAAQUC5hojNrqite6z/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAESFR/9oACAEBAAY/AgbMx81m1//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExQVFh/9oACAEBAAE/Iaihr3AlrVDDKbtadMyDEqZ3icGx/9oADAMBAAIAAwAAABCz4D7/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEAAgIDAQAAAAAAAAAAAAABABExQRAhYbH/2gAIAQEAAT8QQkEqlTeNyk/w1KXM7CXkgKAAt7ByPrO7LU1nUc+kqcBX3h//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-2344",
              "title": "IMG-2344",
              "src": "/static/766af3f0e876dd5efbe879177a0de813/4b190/IMG-2344.jpg",
              "srcSet": ["/static/766af3f0e876dd5efbe879177a0de813/e07e9/IMG-2344.jpg 200w", "/static/766af3f0e876dd5efbe879177a0de813/066f9/IMG-2344.jpg 400w", "/static/766af3f0e876dd5efbe879177a0de813/4b190/IMG-2344.jpg 800w", "/static/766af3f0e876dd5efbe879177a0de813/e5166/IMG-2344.jpg 1200w", "/static/766af3f0e876dd5efbe879177a0de813/b17f8/IMG-2344.jpg 1600w", "/static/766af3f0e876dd5efbe879177a0de813/1deb5/IMG-2344.jpg 1866w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      